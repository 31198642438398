import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import Divider from "../assets/divider.png";

function Pricing({ title, description, color }) {
  const isMobile = window.innerWidth < 700;

  return (
    <Container style={{ marginTop: 100 }} id="#pricing">
      <Row
        className="align-items-center justify-content-center"
        style={{ padding: "30px 10px" }}
      >
        <Col xs={12} md={3}>
          <div className="text-center pricing-box">
            <h2>
              <a style={{ color: "#b695f6" }}>
                <b>Pricing</b>
              </a>
            </h2>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <p style={{fontSize: 24, color: "#d3c7ef"}} className="text-center mb-5">40% of the revenue is used to buyback and burn $CODYN</p>
        </Col>

        <Col xs={12} md={4} className="my-5">
          <Card className="pricing-card-1 text-center">
            <Card.Title className="pricing-title-1">
              <b>Starter</b>
            </Card.Title>
            <p className="pricing-price-1">0.5 ETH per month</p>
            <p className="pricing-staking-1" style={{textDecorationLine: "line-through"}}>$CODYN Staking</p>
          </Card>
        </Col>

        <Col xs={12} md={4} className="my-5">
          <Card className="pricing-card-2 text-center">
            <Card.Title className="pricing-title-2">
              <b>Professional</b>
            </Card.Title>
            <p className="pricing-price-2">0.5 ETH per month</p>
            <p className="pricing-staking-2" >xxx $CODYN Staking</p>
          </Card>
        </Col>

        <Col xs={12} md={4} className="my-5">
          <Card className="pricing-card-3 text-center">
            <Card.Title className="pricing-title-3">
              <b>Business</b>
            </Card.Title>
            <p className="pricing-price-3">Contact us</p>
            <p className="pricing-staking-3">More info</p>
          </Card>
        </Col>

        <Col xs={12} className="d-flex justify-content-center align-items-center mt-5">
          <p className="staking-description text-center">
            Staking will be implemented soon, bringing several benefits from the
            platform such as a better model, autonomous suggestions, automatic
            tasks, automatic code documentation and more.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Pricing;
