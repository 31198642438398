import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import CustomCard from "./CustomCard";
import StepsImage from "../assets/steps.png";

function Steps() {
  const isMobile = window.innerWidth < 700;
  const fixedWidthStyle = {
    width: "200px", // Example fixed width, adjust as needed
    margin: "0", // Removes any default margins
    padding: "10px", // Add some padding inside the divs, adjust as needed
    backgroundColor: "#f0f0f0", // Example background color, adjust as needed
  };

  return (
    <Container style={{ marginTop: 60, marginBottom: 60 }}>
      <Row>
        <Col xs={12} className="d-flex">
          <div
            style={{
              backgroundColor: "#825bd7",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#0e1015",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#0e1015",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <p className="steps-description ms-4 py-4">
            You open an Issue describing a bug to fix or a feature to be added
            and Codyn starts working on it. Codyn can make changes to multiple
            files at once, create or delete files, refactor the code.
          </p>
        </Col>

        <Col xs={12} className="d-flex">
          <div
            style={{
              backgroundColor: "#0e1015",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#b794f5",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#0e1015",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <p className="steps-description ms-4 py-4">
            When the code is ready, Codyn creates a Pull Request that you are
            able to accept, delete or edit normally. You can also provide feedback
            in the comments and Codyn will make changes to act on that feedback.
          </p>
        </Col>

        <Col xs={12} className="d-flex">
          <div
            style={{
              backgroundColor: "#0e1015",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#0e1015",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#d4c9f1",
              width: "20px",
              height: "100%",
            }}
          ></div>
          <p className="steps-description ms-4 py-4">
            Codyn can work on as many Issue at the same time as you want.
            Ask Codyn to implement a new feature while it adds tests and documentation to the existing ones
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Steps;
