import React from 'react';
import { Card } from 'react-bootstrap';

const CustomCard = ({ title, subtitle, description }) => {
  return (
    <div className="custom-container">
      <div className="title-container">
        <h3 className="title"><b>{title}</b></h3>
        <h5 className="subtitle">{subtitle}</h5>
      </div>
      <p className="description" style={{ maxWidth: 800, paddingLeft: 20}}>{description}</p>
    </div>
  );
};

export default CustomCard;