import React from 'react';
import { Card } from 'react-bootstrap';

const CustomCardRoadmap = ({ title, subtitle, description }) => {
  return (
    <div className="custom-container-roadmap mb-5">
      <div className="title-container-roadmap">
        <h3 className="title-roadmap"><b>{title}</b></h3>
        <p className="subtitle-roadmap">{subtitle}</p>
      </div>
    </div>
  );
};

export default CustomCardRoadmap;