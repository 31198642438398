import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import BarsImage from "../assets/vertical.png";
import CustomCardRoadmap from "./CustomCardRoadmap";
import Box1 from "../assets/box_1.png";


function Roadmap() {
  const isMobile = window.innerWidth < 700;

  return (
    <Container style={{ marginTop: 60 }}>
      <Row className="d-flex align-items-center justify-content-center mb-5">
        <Col xs={12} md={6} className="position-relative text-center">
          <img
            style={{ width: "100%" }}
            src={Box1}
            alt="Descriptive Alt Text"
          />
          <div
            className="overlay-text"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#d4c6f1",
              fontSize: "20px",
            }}
          >
            <h1>
              <b>Roadmap</b>
            </h1>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={2} md={1}>
          <img
            style={{ height: "1400px", marginLeft: 24, marginTop: 30 }}
            src={BarsImage}
            alt="Bars"
          />
        </Col>
        <Col xs={10} md={3}>
          <CustomCardRoadmap
            title={"Launch"}
            subtitle={"First implementation."}
          />

          <CustomCardRoadmap
            title={"Ultra Model Preview"}
            subtitle={"Ultra model preview in closed beta."}
          />

          <CustomCardRoadmap
            title={"Staking"}
            subtitle={"Enable staking to access more powerful models."}
          />

          <CustomCardRoadmap
            title={"Ultra Model Release"}
            subtitle={"Use our most powerful model."}
          />

          <CustomCardRoadmap
            title={"Custom Instructions"}
            subtitle={"Provide custom instructions with a JSON config file."}
          />

          <CustomCardRoadmap
            title={"Automatic Development Mode"}
            subtitle={"Enable Codyn to write its own issues."}
          />

          <CustomCardRoadmap
            title={"Sandbox Testing"}
            subtitle={"Codyn tests the PR in a container environment and makes adjustments."}
          />

          <CustomCardRoadmap
            title={"Automatic Deployment"}
            subtitle={"Codyn will be able to deploy in a staging environment."}
          />

        </Col>
      </Row>
    </Container>
  );
}

export default Roadmap;
