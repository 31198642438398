import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import BarsImage from "../assets/bars.png"
import CustomCard from "./CustomCard";


function CodyCanBe() {
  const isMobile = window.innerWidth < 700;

  return (
    <Container style={{ marginTop: 60 }}>
      <Row className="align-items-center mb-5">
        <Col xs={12} md={6} style={{color: "#d9cdf5"}}>
          <h1>Codyn can be your</h1>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={2} md={1}>
          <img style={{width: "100%", height: "80%", marginTop: 30}} src={BarsImage} alt="Bars" />
        </Col>
        <Col xs={10} md={3}>
          <CustomCard 
          title={"Frontend Developer"}
          subtitle={"React"}
          description={"How would you like to change this frontend component? Just tell Codyn and he will do the rest."}
          />

          <CustomCard 
          title={"Backend Developer"}
          subtitle={"Flask"}
          description={"Ask Codyn to develop an API endpoint from a given list, write a project configuration or docs."}
          />

          <CustomCard 
          title={"DevOps Engineer"}
          subtitle={"Docker"}
          description={"Ask Codyn to add a docker-compose and Dockerfile to your project."}
          />

          <CustomCard 
          title={"Blockchain Automated Auditor"}
          subtitle={"Solidity"}
          description={"Ask Codyn to audit your smart contracts and check for basic Solidity vulnerabilities."}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default CodyCanBe;
