import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import Chart from "../assets/chart.png";
import Box2 from "../assets/box_2.png";

function Tokenomics() {
  const isMobile = window.innerWidth < 700;

  return (
    <Container style={{ marginTop: 60 }}>
      <Row className="d-flex align-items-center justify-content-center mb-5">
        <Col xs={12} md={6} className="position-relative text-center">
          <img
            style={{ width: "100%" }}
            src={Box2}
            alt="Descriptive Alt Text"
          />
          <div
            className="overlay-text"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#b795f5",
              fontSize: "20px",
            }}
          >
            <h1>
              <b>Tokenomics</b>
            </h1>
          </div>
        </Col>
      </Row>

      <Row  style={{marginBottom: 300}}>
        <Col xs={12}>
          <p style={{ color: "#835cd8", padding: "0px 32px" }}>
            Codyn integrates the $CODYN token as a means to create a positive
            feedback loop that is going to sustain the continous improvement of
            Codyn while rewarding its users and supporters.
          </p>
          <p style={{ color: "#d4c0fa", marginBottom: 50, padding: "0px 32px" }}>
            Below are the key aspects of the tokenomics:
          </p>
        </Col>
        <Col xs={12}>
          <Card className="roadmap-card" style={{ borderRadius: 45, padding: isMobile ? 20 : 60 }}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <div style={{ maxWidth: 400, marginBottom: 60}}>
                    <h2 className="tokenomics-title">Subscription Model</h2>
                    <p style={{fontSize: 15}}>
                      Users can easily pay for their subseriptions using ETH,
                      ensuring a straightforward and accessible payment method
                      that creates stable and predictable costs for using Codyn.
                      This is appealing for retail as well as enter- prise
                      users.
                    </p>
                  </div>

                  <div style={{ maxWidth: 400, marginBottom: 60}}>
                    <h2 className="tokenomics-title">Staking</h2>
                    <p style={{fontSize: 15}}>
                      To unlock higher-tier models withwithin Codyn, users are
                      required to stake $CODYN tokens. This staking mechanism
                      incentivizes token holding and adds an element of
                      exclusivity to the app's advanced features. As users stake
                      more $CODYN to access better features, they directly
                      contribute to the app's ecosystem.
                    </p>
                  </div>

                  <div style={{ maxWidth: 400, marginBottom: 60}}>
                    <h2 className="tokenomics-title">Buyback and Burn</h2>
                    <p style={{fontSize: 15}}>
                      40% of all subscription fees collected are allocated
                      towards buying back $CODYN from the market. These tokens
                      are subsequently burned, effectively reducing the overall
                      supply. This process creates a positive feedback loop: as
                      Codyn grows and attracts more subscribers, more $CODYN is
                      bought back and burned, potentially enhan- cing the
                      token's value for existing holders.
                    </p>
                  </div>

                  <div style={{ maxWidth: 400, marginBottom: 60}}>
                    <h2 className="tokenomics-title">Buyback and Burn</h2>
                    <p style={{fontSize: 15}}>
                      20% of all subscription fees collected are allocated
                      towards marketing since a larger user base allows to have
                      more resources for model development and a broader range
                      of feedbacks for the development team to act upon. 15% of
                      all subscription fees collected are allocated towards the
                      team to work on the next iterations full time.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div style={{ maxWidth: 400, marginBottom: 60}}>
                    <h2 className="tokenomics-title">Model Development</h2>
                    <p style={{fontSize: 15}}>
                      25% of all subscription fees collected are allocated
                      towards developing the underlying deep learning models
                      that power the Al bot. This ensures that Codyn mantains a
                      competitive advantage in the market.
                    </p>
                  </div>

                  <div className="text-center" style={{padding: isMobile? "0px" : "30px 80px"}}>
                    <img
                      style={{ width: "100%", marginTop: 60 }}
                      src={Chart}
                      alt="Descriptive Alt Text"
                    />

                    <div
                      style={{
                        backgroundColor: "#202430",
                        borderRadius: "10px",
                        width: "100%",
                        marginTop: 80
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#d3c5ef",
                            marginRight: "10px",
                            borderRadius: "30%",
                          }}
                        ></div>
                        <div style={{ color: "#d9cdf5", fontSize: "16px" }}>
                          Team
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#b795f5",
                            marginRight: "10px",
                            borderRadius: "30%",
                          }}
                        ></div>
                        <div style={{ color: "#d9cdf5", fontSize: "16px" }}>
                          Buyback and Burn
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#815bd5",
                            marginRight: "10px",
                            borderRadius: "30%",
                          }}
                        ></div>
                        <div style={{ color: "#d9cdf5", fontSize: "16px" }}>
                          Marketing
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#382276",
                            marginRight: "10px",
                            borderRadius: "30%",
                          }}
                        ></div>
                        <div style={{ color: "#d9cdf5", fontSize: "16px" }}>
                          Development
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Tokenomics;
