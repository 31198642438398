import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import Divider from "../assets/divider.png"


function BulletPoint({title, description, color}) {
  const isMobile = window.innerWidth < 700;

  return (
    <Container>
      <Row style={{ padding: "30px 10px" }} className="align-items-center">
        <Col xs={12} md={6} style={{direction: isMobile? "ltr" : "rtl", }}>
          <p className="bullet-title" style={{color: color}}><b>{title}</b></p>
        </Col>
        <Col className="bullet-description" xs={12} md={6}>
            <p>{description}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <img style={{width: "100%", height: "2px"}} src={Divider} />
        </Col>
      </Row>
    </Container>
  );
}

export default BulletPoint;
