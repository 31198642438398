import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import GitLogo from "../assets/github.png"

function GitHubPullRequest() {
  const isMobile = window.innerWidth < 700;

  return (
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <h1 style={{color: "#d9cdf5"}}>Get it done in just a</h1>
          <h1 style={{color: "#d9cdf5"}}>GitHub Pull Request</h1>
          <p>
            With Codyn, it's as simple as opening a GitHub Issue describing what
            you want to do. Codyn will open a Pull Request with the requested
            changes.
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center" style={{marginTop: isMobile ? 40 : 0}}>
            <img style={{width: "30%"}} src={GitLogo} />
        </Col>
      </Row>
    </Container>
  );
}

export default GitHubPullRequest;
