import React from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
  Accordion,
} from "react-bootstrap";

const BeyonHumanCoding = () => {
  const isMobile = window.innerWidth < 700;
  return (
    <Container style={{ marginTop: 100 }}>
      <Row>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="text-center beyond-box">
            <h2>
              <a style={{ color: "#b695f6" }}>
                <b>Beyond_Human_Coding</b>
              </a>
            </h2>
          </div>
        </Col>
        <Col
          className={`d-flex align-items-center ${
            isMobile ? "text-center mt-4" : "justify-content-end"
          }`}
          style={{
            paddingLeft: isMobile ? 10 : 100,
            fontSize: 20,
            direction: "rtl",
          }}
          xs={12}
          md={6}
        >
          <p>
            Codyn is an AI Software Engineer that is able to collaborate with you
            working directly on your GitHub repositories
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default BeyonHumanCoding;
